import { Paper } from "@material-ui/core";
import React from "react";
import { Redirect, Route } from "react-router";
import LoginForm from "../Components/LoginForm";
import ForgotPasswordForm from "../Components/ForgotPasswordForm";
import RegistrationForm from "../Components/RegistrationForm";
import Roses from "../Images/Login_Roses_1920.jpg";
import CustomSwitch from "../Components/CustomSwitch";
import { useSelector } from "react-redux";

const LoginPage = (props) => {
  const isLoggedIn = useSelector((state) => state.UserState.isLoggedIn);
  if (isLoggedIn) return <Redirect to="/dashboard" />;
  return (
    <div
      style={{
        overflow: "auto",
        height: "100vh",
        backgroundImage: `url(${Roses})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <Paper
        style={{
          width: 500,
          marginTop: 100,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
      >
        <CustomSwitch>
          <Route path={`${props.match.path}/login`} component={LoginForm} />
          <Route
            path={`${props.match.path}/register`}
            component={RegistrationForm}
          />
          <Route
            path={`${props.match.path}/forgot_password`}
            component={ForgotPasswordForm}
          />
        </CustomSwitch>
      </Paper>
    </div>
  );
};

export default LoginPage;
