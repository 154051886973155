import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import ProtectedRoute from "./Components/ProtectedRoute";
import HomeView from "./Views/HomeView";
import LoginView from "./Views/LoginView";
import DashBoardView from "./Views/DashBoardView";
import NotFound from "./Components/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "./Redux/Actions/userActions";
import { retrieveUserFromLocalStorage } from "./Api/cognito";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.UserState.isLoggedIn);

  useEffect(() => {
    retrieveUserFromLocalStorage()
      .then((userObject) => {
        dispatch(loginUser(userObject));
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("User has not logged in yet.");
      });
  }, []);
  if (isLoading) return <React.Fragment />;
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={HomeView} />
      <Route path="/auth" component={LoginView} />
      <Route path="/404" component={NotFound} />
      <ProtectedRoute
        path="/dashboard"
        isAuthenticated={isLoggedIn}
        component={DashBoardView}
      />
    </Switch>
  );
}

export default App;
