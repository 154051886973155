import { TextField, Typography, TextFieldProps } from "@material-ui/core";
import React, { useState } from "react";
import {
  checkUppercase,
  checkLowercase,
  checkNumber,
} from "../Utils/checkPassword";

const PasswordField = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([""]);

  const changePassword = (event) => {
    let errorMessages = [];
    let isError = false;
    if (event.target.value.length < 8) {
      isError = true;
      errorMessages.push("Password should have at least 8 characters");
    }
    if (!checkUppercase(event.target.value)) {
      isError = true;
      errorMessages.push("Password should have a uppercase letter");
    }
    if (!checkLowercase(event.target.value)) {
      isError = true;
      errorMessages.push("Password should have a lowercase letter");
    }
    if (!checkNumber(event.target.value)) {
      isError = true;
      errorMessages.push("Password should have a number");
    }
    setError(isError);
    setErrorMessage(errorMessages);
    if (!isError) props.onValidPassword(event.target.value);
    else {
      props.onInvalidPassword();
    }
  };
  return (
    <React.Fragment>
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        onChange={changePassword}
        error={error || props.error}
        style={props.style}
        required={props.required}
      />
      {errorMessage.map((message) => {
        return (
          <Typography
            key={message}
            style={{
              color: "#B00020",
              fontWeight: 400,
              fontSize: 10,
              fontStyle: "normal",
            }}
          >
            {message}
          </Typography>
        );
      })}
    </React.Fragment>
  );
};

export default PasswordField;
