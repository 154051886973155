import React from "react";
import { Redirect, Route, Switch } from "react-router";

const CustomSwitch = (props) => {
  return (
    <Switch>
      {props.children}
      <Route
        render={(props) => {
          return <Redirect to="/404" />;
        }}
      />
    </Switch>
  );
};

export default CustomSwitch;
