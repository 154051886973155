import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { createBoard } from "../Api/BoardApi";

const useStyles = makeStyles((theme) => ({
  item: {
    width: "fit-content",
    margin: "auto",
  },
}));

const Boards = (props) => {
  const classes = useStyles();
  const [boardName, setBoardName] = useState("");

  const changeBoardName = (event) => {
    setBoardName(event.target.value);
  };

  const submitBoard = () => {
    createBoard({ name: boardName });
  };

  return (
    <Grid containerdirection="column" justify="center">
      <Grid item className={classes.item}>
        <TextField label="Board Name" onChange={changeBoardName} />
      </Grid>
      <Grid item className={classes.item}>
        <Button onClick={submitBoard}>Create Board</Button>
      </Grid>
    </Grid>
  );
};

export default Boards;
