import React, { useState } from "react";
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import LoginHeader from "./LoginHeader";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { signInUser } from "../Api/cognito";
import { loginUser } from "../Redux/Actions/userActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: 25,
    margin: "auto",
  },
}));

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { from } = location.state || { from: { pathname: "/" } };

  const onLogin = () => {
    signInUser({ email, password }).then((userState) => {
      dispatch(loginUser(userState));
      history.replace(from);
    });
  };

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <React.Fragment>
      <LoginHeader
        classNam={classes.item}
        title="Sign In"
        icon={<LockOutlinedIcon />}
      />
      <Grid
        container
        direction="column"
        justify="center"
        style={{ width: 300, margin: "auto" }}
      >
        <Grid item className={classes.item}>
          <TextField label="Email" variant="outlined" onChange={changeEmail} />
        </Grid>
        <Grid item className={classes.item}>
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            onChange={changePassword}
            onKeyDown={(event) => {
              if (event.key === "Enter") onLogin();
            }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.item}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ width: 100 }}
            onClick={onLogin}
          >
            SIGN IN
          </Button>
          <Link
            to="/auth/forgot_password/email"
            style={{ textDecoration: "none", marginLeft: 20 }}
          >
            <Button color="primary">FORGOT PASSWORD</Button>
          </Link>
        </Grid>
        <Grid
          item
          className={classes.item}
          style={{ margin: "auto", marginBottom: 10, marginTop: 150 }}
        >
          <Typography>
            Don't have an account?
            <Link
              to="/auth/register"
              style={{ textDecoration: "none", marginLeft: 20 }}
            >
              <Button color="primary">Register</Button>
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LoginForm;
