import axios from "axios";

const PRIVATE_URL = "https://icnekz4y4a.execute-api.us-east-2.amazonaws.com";
const PUBLIC_URL = "";
const STAGE = "/dev";

const isLoggedIn = () => {
  return localStorage.getItem("id_token") !== null;
};

const getUserToken = () => {
  return localStorage.getItem("id_token");
};

export const PrivateApi = {
  get: (url) => {
    if (!isLoggedIn())
      return Promise.reject(
        "The user is not logged in and cannot access the private api."
      );
    return axios.get(PRIVATE_URL + STAGE + url, {
      headers: { Authorization: getUserToken() },
    });
  },
  put: (url, data) => {
    if (!isLoggedIn())
      return Promise.reject(
        "The user is not logged in and cannot access the private api."
      );
    return axios.put(PRIVATE_URL + STAGE + url, data, {
      headers: { Authorization: getUserToken() },
    });
  },
  post: (url, data) => {
    if (!isLoggedIn())
      return Promise.reject(
        "The user is not logged in and cannot access the private api."
      );
    return axios.post(PRIVATE_URL + STAGE + url, data, {
      headers: { Authorization: getUserToken() },
    });
  },
  delete: (url) => {
    if (!isLoggedIn())
      return Promise.reject(
        "The user is not logged in and cannot access the private api."
      );
    return axios.get(PRIVATE_URL + STAGE + url, {
      headers: { Authorization: getUserToken() },
    });
  },
};
