import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import LoginHeader from "./LoginHeader";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import CustomSwitch from "./CustomSwitch";
import PasswordField from "./PasswordField";
import { forgotPassword, resetVerificationPIN } from "../Api/cognito";

const useStyles2 = makeStyles((theme) => ({
  item: { margin: "auto", width: "fit-content", marginBottom: 25 },
}));

let cognitoPackage = null;

const StageOne = (props) => {
  const resetPassword = () => {
    forgotPassword(props.email).then((cognitoUserPackage) => {
      cognitoPackage = cognitoUserPackage;
    });
  };

  return (
    <React.Fragment>
      <LoginHeader
        icon={<LockOutlinedIcon />}
        title="Forgot Password"
        subTitle="Enter the email for the account you wish to reset."
      />
      <Grid container direction="column" justify="center">
        <Grid item style={{ margin: "auto" }}>
          <TextField
            label="Email"
            variant="outlined"
            style={{ marginBottom: 25, marginTop: 50 }}
            onChange={props.changeEmail}
          />
        </Grid>
        <Grid item style={{ margin: "auto", marginBottom: 20, marginTop: 30 }}>
          <Link
            to="/auth/forgot_password/reset"
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" variant="contained" onClick={resetPassword}>
              Continue
            </Button>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const StageTwo = (props) => {
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const classes = useStyles2();
  const history = useHistory();

  const changeCode = (event) => {
    setCode(event.target.value);
  };

  const changePassword = (event) => {
    setNewPassword(event.target.value);
  };

  const invalidatePassword = () => {
    setNewPassword("");
  };

  const changeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const verify = (event) => {
    if (newPassword !== confirmPassword || newPassword === "") {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (cognitoPackage.cognitoUser)
        cognitoPackage.cognitoUser.confirmPassword(code, newPassword, {
          onSuccess: () => {
            history.push("/auth/login");
          },
          onFailure: (e) => {
            console.log(e);
          },
        });
    }
  };

  return (
    <React.Fragment>
      <LoginHeader
        icon={<LockOutlinedIcon />}
        title="Forgot Password"
        subTitle="Enter the code you received in your email."
      />
      <Grid
        container
        direction="column"
        justify="center"
        style={{ width: 300, margin: "auto", display: "block" }}
      >
        <Grid item className={classes.item}>
          <TextField label="Code" variant="outlined" onChange={changeCode} />
        </Grid>
        <Grid item className={classes.item}>
          <PasswordField
            onValidPassword={changePassword}
            onInvalidPassword={invalidatePassword}
          />
        </Grid>
        <Grid item className={classes.item}>
          <TextField
            label="Confirm Password"
            variant="outlined"
            onChange={changeConfirmPassword}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ margin: "auto", marginBottom: 25 }}
        >
          <Link to="/auth/login" style={{ textDecoration: "none" }}>
            <Button variant="contained" color="primary" onClick={verify}>
              Submit
            </Button>
          </Link>
          <Button
            color="primary"
            onClick={() => {
              resetVerificationPIN(props.email);
            }}
          >
            Resend Code
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState("");

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <React.Fragment>
      <CustomSwitch>
        <Route
          path={`${props.match.path}/email`}
          render={(props) => (
            <StageOne {...props} email={email} changeEmail={changeEmail} />
          )}
        />
        <Route
          path={`${props.match.path}/reset`}
          render={(props) => <StageTwo {...props} email={email} />}
        />
      </CustomSwitch>
    </React.Fragment>
  );
};

export default ForgotPasswordForm;
