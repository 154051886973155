import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import CustomSwitch from "./CustomSwitch";
import LoginHeader from "./LoginHeader";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PasswordField from "./PasswordField";
import {
  signUpUser,
  verifyUserAccount,
  resetVerificationPIN,
} from "../Api/cognito";

const useStyles1 = makeStyles((theme) => ({
  item: {
    margin: "auto",
    width: "fit-content",
    marginBottom: 25,
  },
}));

const useStyles2 = makeStyles((theme) => ({
  gridRoot: {
    width: 300,
    margin: "auto",
  },
}));

const StageOne = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  const classes = useStyles1();

  const changeUsername = (event) => {
    setUsername(event.target.value);
  };

  const changePassword = (password) => {
    setPassword(password);
  };

  const changeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const invalidatePassword = () => {
    setPassword("");
  };

  const register = (event) => {
    if (
      password !== confirmPassword ||
      password === "" ||
      props.email === "" ||
      username === ""
    ) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    signUpUser({ username, email: props.email, password })
      .then((resp) => {
        console.log(resp);
        history.push(`${props.match.path}/verify`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <LoginHeader
        icon={<LockOutlinedIcon />}
        title="Register"
        subTitle="Please enter your desired username, email, and password to use this site."
      />
      <Grid container direction="column" justify="center">
        <Grid item className={classes.item}>
          <TextField
            label="Username"
            variant="outlined"
            onChange={changeUsername}
          />
        </Grid>
        <Grid item className={classes.item}>
          <TextField
            label="Email"
            variant="outlined"
            onChange={props.changeEmail}
          />
        </Grid>
        <Grid item className={classes.item}>
          <PasswordField
            onValidPassword={changePassword}
            onInvalidPassword={invalidatePassword}
          />
        </Grid>
        <Grid item className={classes.item}>
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            onChange={changeConfirmPassword}
          />
        </Grid>
        <Grid item className={classes.item}>
          <Link
            to={`${props.match.path}/verify`}
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" variant="contained" onClick={register}>
              Continue
            </Button>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const StageTwo = (props) => {
  const [code, setCode] = useState("");

  const classes = useStyles2();
  const history = useHistory();

  const changeCode = (event) => {
    setCode(event.target.value);
  };

  const verify = () => {
    verifyUserAccount({ email: props.email, pin: code })
      .then(() => {
        history.push("/auth/login");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <React.Fragment>
      <LoginHeader
        icon={<LockOutlinedIcon />}
        title="Register"
        subTitle="Enter the code that was sent to your email"
      />
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.gridRoot}
      >
        <Grid item style={{ margin: "auto", marginBottom: 25 }}>
          <TextField label="Code" variant="outlined" onChange={changeCode} />
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ marginBottom: 25 }}
        >
          <Button variant="contained" color="primary" onClick={verify}>
            Continue
          </Button>
          <Button
            color="primary"
            onClick={() => {
              resetVerificationPIN(props.email);
            }}
          >
            Resend Code
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const RegistrationForm = (props) => {
  const [email, setEmail] = useState("");

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  return (
    <CustomSwitch>
      <Route
        exact
        path={`${props.match.path}`}
        render={(props) => (
          <StageOne {...props} changeEmail={changeEmail} email={email} />
        )}
      />
      <Route
        path={`${props.match.path}/verify`}
        render={(props) => <StageTwo {...props} email={email} />}
      />
    </CustomSwitch>
  );
};

export default RegistrationForm;
