import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import UserState from "./Reducers/userReducer";

const rootReducer = combineReducers({ UserState });

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));
export default store;