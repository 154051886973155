export const checkPassword = (password) => {
  var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  return password.match(regex);
};

export const checkUppercase = (password) => {
  return password.toLowerCase() !== password;
};

export const checkLowercase = (password) => {
  return password.toUpperCase() !== password;
};

export const checkSpecialCharacter = (password) => {
  return !/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(password);
};

export const checkNumber = (password) => {
  return /\d/.test(password);
};
