import { Avatar, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: "auto",
    display: "block",
    backgroundColor: theme.palette.primary.main,
  },
  typography: {
    textAlign: "center",
    width: 400
  },
  root: {
    margin: "auto",
    display: "block",
    width: "fit-content",
    marginTop: 50,
    marginBottom: 50,
  },
}));

const LoginHeader = (props) => {
  const classes = useStyles();
  let icon = undefined;
  if (props.icon)
    icon = React.cloneElement(props.icon, {
      style: { height: 30, width: 30, marginLeft: 5, marginTop: 5 },
    });
  return (
    <div className={classes.root}>
      {icon ? (
        <Avatar className={classes.icon}>{icon}</Avatar>
      ) : (
        <React.Fragment />
      )}
      <Typography
        variant="h3"
        className={classes.typography}
        style={{ marginTop: 25 }}
      >
        {props.title}
      </Typography>
      <Typography variant="subtitle1" className={classes.typography}>
        {props.subTitle}
      </Typography>
    </div>
  );
};

export default LoginHeader;
