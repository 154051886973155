import { CognitoUserPool } from "amazon-cognito-identity-js";

const REGION = "us-east-2";
const USER_POOL_ID = "us-east-2_sDEXqjFMC";
const CLIENT_ID = "2krjdulpsr158rlcuskliq3lll"; //Make the app client have no secret, client side js doesn't support secrets

const userData = {
  UserPoolId: USER_POOL_ID,
  ClientId: CLIENT_ID,
};

export const userPool = new CognitoUserPool(userData);
export const USERPOOL_ID =
  "cognito-idp." + REGION + ".amazonaws.com/" + USER_POOL_ID;
