import React from "react";
import { Route } from "react-router";
import CustomSwitch from "../Components/CustomSwitch";
import Dashboard from "../Components/Dashboard";
import Boards from "../Components/Boards";
const PrivateView = (props) => {
  console.log(`${props.match.path}`);
  return (
    <Dashboard>
      <CustomSwitch>
        <Route exact path={`${props.match.path}`} />
        <Route path={`${props.match.path}/boards`} component={Boards} />
      </CustomSwitch>
    </Dashboard>
  );
};

export default PrivateView;
